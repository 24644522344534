@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap';

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Color System Convention
 *
 * Our design system follows a simple background/foreground pattern for colors:
 * - `--{color}`: The background color of the component (e.g., --primary, --secondary)
 * - `--{color}-foreground`: The text color to use on that background (e.g., --primary-foreground)
 *
 * Example usage:
 * ```css
 * .primary-button {
 *   background-color: hsl(var(--primary));
 *   color: hsl(var(--primary-foreground));
 * }
 * ```
 *
 * All colors are defined as HSL values without the hsl() wrapper to allow for opacity modifications:
 * - ✅ `--primary: 222 47% 11%;`
 * - ❌ `--primary: hsl(222 47% 11%);`
 */

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
  :root {
    /* Default background color of components */
    --background: 0 0% 100%;
    --foreground: 160 0% 10%;

    /* Default background color of <body /> */
    --body-background: 210 29% 97%;
    --body-foreground: 160 0% 10%;

    /* Primary colors for <Button /> */
    --primary: 160 72.7% 27.3%;
    --primary-foreground: 0 0% 100%;

    /* Secondary colors for <Button /> */
    --secondary: 0 0% 100%;
    --secondary-foreground: 160 0% 10%;

    /* Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch /> */
    --muted: 210 29% 97%;
    --muted-foreground: 216 17% 41%;

    /* Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem> */
    --accent: 122 10% 95%;
    --accent-foreground: 160 0% 15%;

    /* Used for destructive actions such as <Button variant="destructive">, error messages */
    --destructive: 0 50% 50%;
    --destructive-foreground: 0 0% 100%;

    /* Default border color */
    --border: 207 26% 92%;

    /* Used for focus ring */
    --ring: 160 72.7% 27.3%;

    /* Border color for inputs such as <Input />, <Select />, <Textarea /> */
    --input: 207 26% 92%;

    /* Background color for <Card /> */
    --card: 0 0% 100%;
    --card-foreground: 160 0% 10%;

    /* Used for badges background color */
    --red: 0 86% 65%;
    --red-foreground: 0 100% 94%;
    --spice: 22 100% 43%;
    --spice-foreground: 36 100% 91%;
    --green: 160 72.7% 27.3%;
    --green-foreground: 162.86 41.18% 93.33%;
    --lime: 90 71% 51%;
    --lime-foreground: 91 74% 94%;
    --purple: 259 46% 48%;
    --purple-foreground: 268 67% 93%;
    --indigo: 220 69% 38%;
    --indigo-foreground: 217 60% 94%;
    --peacock: 190 70% 35%;
    --peacock-foreground: 189 65% 90%;
    --blue: 213 51% 48%;
    --blue-foreground: 199 100% 94%;
    --cyan: 190 66% 46%;
    --cyan-foreground: 189 66% 92%;

    /* Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover /> */
    --popover: 0 0% 100%;
    --popover-foreground: 160 0% 10%;

    /* Navigation colors */
    --navigation-background: 210 29% 97;
    --navigation-foreground: 160 0% 10%;
    /* Used for active state */
    --navigation-primary: 91 74% 94%;
    --navigation-primary-foreground: 160 72.7% 27.3%;
    --navigation-primary-border: 90 50% 85.1%;
    /* Used for hover states */
    --navigation-accent: 0 0% 100%;
    --navigation-accent-foreground: 160 0% 10%;
    /* Used for borders and separators */
    --navigation-border: 207 26% 92%;
    /* Used for focus rings */
    --navigation-ring: 160 72.7% 27.3%;

    /* Border radius for card, input and buttons */
    --radius: 0.5rem;
  }
  .dark {
    --background: 210 29% 10%;
    --foreground: 160 0% 98%;

    --body-background: 240 4% 16%;
    --body-foreground: 160 0% 98%;

    --primary: 160 72.7% 27.3%;
    --primary-foreground: 0 0% 100%;

    --secondary: 160 10% 20%;
    --secondary-foreground: 0 0% 100%;

    --muted: 122 10% 15%;
    --muted-foreground: 160 0% 65%;

    --accent: 122 10% 20%;
    --accent-foreground: 160 0% 90%;

    --destructive: 0 86% 65%;
    --destructive-foreground: 0 0% 100%;

    --border: 207 26% 20%;

    --ring: 160 72.7% 27.3%;

    --input: 207 26% 20%;

    --card: 160 0% 5%;
    --card-foreground: 160 0% 98%;

    --red: 0 86% 65%;
    --red-foreground: 0 100% 94%;
    --spice: 22 100% 43%;
    --spice-foreground: 36 100% 91%;
    --green: 160 72.7% 27.3%;
    --green-foreground: 162.86 41.18% 93.33%;
    --lime: 90 71% 51%;
    --lime-foreground: 91 74% 94%;
    --purple: 259 46% 48%;
    --purple-foreground: 268 67% 93%;
    --indigo: 220 69% 38%;
    --indigo-foreground: 217 60% 94%;
    --peacock: 190 70% 35%;
    --peacock-foreground: 189 65% 90%;
    --blue: 213 51% 48%;
    --blue-foreground: 199 100% 94%;
    --cyan: 190 66% 46%;
    --cyan-foreground: 189 66% 92%;

    --popover: 210 29% 10%;
    --popover-foreground: 160 0% 98%;

    --navigation-background: 222 47% 11%;
    --navigation-foreground: 210 40% 98%;
    --navigation-primary: 160 72.7% 27.3%;
    --navigation-primary-foreground: 0 0% 100%;
    --navigation-primary-border: 160 72.7% 27.3%;
    --navigation-accent: 217 19% 27%;
    --navigation-accent-foreground: 210 40% 98%;
    --navigation-border: 215 27.9% 16.9%;
    --navigation-ring: 160 72.7% 27.3%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-body-background text-foreground;
  }
}

@layer components {
  /* HexColorPicker */
  .hex-color-picker.react-colorful {
    @apply w-full h-full aspect-[4/3];
  }
  .hex-color-picker .react-colorful__saturation {
    @apply rounded-lg border-none;
  }
  .hex-color-picker .react-colorful__hue {
    @apply mt-4 rounded-2xl h-3;
  }
  .hex-color-picker .react-colorful__pointer {
    @apply border-8 w-8 h-8;
  }
}
